.login-form {
    height: max-content;
}
.login__container {
    width: 80%;
    height: 70vh !important;
    margin: auto;
}
.login__container img {
    max-width: 100%;
    padding: 20px;
}
.forgotten-password-form {
    .forgotten-btn {
        background-color: $indepMobBlack-color !important;
        color: $primary-text !important;
    }
}
