/**
Independent Mobile colors
*/
$indep_mobile-color: #ae048f;
$indep_mobile_dark-color: #670355;
$indep_mobile_light-color: #ae048f;
$indepMobBlue: #1990cb;
$indepMobGray-color: #f2f2f4;
$indepMobBlack-color: #111111;
$indepMobBlackLight-color: #898989;
// $color: rgba(221, 221, 221, .8);
$color: #ae048f;
$passed: #9fc30d;
$active: $indep_mobile_light-color;
$warning: #ed6c02;
$black-color: #111111;
$primary-color: #ae048f;
$secondary-color: #ae048f;
$primary-border: 1px solid rgba(221, 221, 221, 0.8666666667);

$primary-text: white;
$secondary-text: black;
